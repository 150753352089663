exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-accessibility-tsx": () => import("./../../../src/pages/accessibility.tsx" /* webpackChunkName: "component---src-pages-accessibility-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pages-about-tsx": () => import("./../../../src/pages/pages/about.tsx" /* webpackChunkName: "component---src-pages-pages-about-tsx" */),
  "component---src-pages-pages-confirm-prescription-tsx": () => import("./../../../src/pages/pages/confirm-prescription.tsx" /* webpackChunkName: "component---src-pages-pages-confirm-prescription-tsx" */),
  "component---src-pages-pages-contact-tsx": () => import("./../../../src/pages/pages/contact.tsx" /* webpackChunkName: "component---src-pages-pages-contact-tsx" */),
  "component---src-pages-pages-privacy-policy-tsx": () => import("./../../../src/pages/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-pages-privacy-policy-tsx" */),
  "component---src-pages-pages-rx-faq-tsx": () => import("./../../../src/pages/pages/rx-faq.tsx" /* webpackChunkName: "component---src-pages-pages-rx-faq-tsx" */),
  "component---src-pages-pages-shipping-info-tsx": () => import("./../../../src/pages/pages/shipping-info.tsx" /* webpackChunkName: "component---src-pages-pages-shipping-info-tsx" */),
  "component---src-pages-pages-warranty-info-tsx": () => import("./../../../src/pages/pages/warranty-info.tsx" /* webpackChunkName: "component---src-pages-pages-warranty-info-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-templates-collection-contentful-tsx": () => import("./../../../src/templates/collection-contentful.tsx" /* webpackChunkName: "component---src-templates-collection-contentful-tsx" */),
  "component---src-templates-collection-tsx": () => import("./../../../src/templates/collection.tsx" /* webpackChunkName: "component---src-templates-collection-tsx" */),
  "component---src-templates-customize-tsx": () => import("./../../../src/templates/customize.tsx" /* webpackChunkName: "component---src-templates-customize-tsx" */),
  "component---src-templates-gift-card-tsx": () => import("./../../../src/templates/gift-card.tsx" /* webpackChunkName: "component---src-templates-gift-card-tsx" */),
  "component---src-templates-learn-more-index-tsx": () => import("./../../../src/templates/learn-more/index.tsx" /* webpackChunkName: "component---src-templates-learn-more-index-tsx" */),
  "component---src-templates-product-customizable-tsx": () => import("./../../../src/templates/product-customizable.tsx" /* webpackChunkName: "component---src-templates-product-customizable-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-variant-collection-tsx": () => import("./../../../src/templates/variant-collection.tsx" /* webpackChunkName: "component---src-templates-variant-collection-tsx" */)
}

